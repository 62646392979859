<template>
  <v-layout align-start
            justify-start
            column
            fill-height
            wrap
            class="white">
    <router-view />
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import config from '../../../config'

export default {
  name: 'pibot-assets-view',
  created () {
    if (!this.assetsLoaded) {
      this.fetchAssets()
        .then(() => {
          this.initDrawerItems()
        })
    }
  },
  computed: {
    ...mapState({
      matrixPending: state => state.matrix.dataPending,
      drawerItems: state => state.app.drawerItems,
      assetOptions: state => state.assets.assetOptions
    }),
    ...mapGetters({
      assetsLoaded: 'assets/assetsLoaded'
    })
  },
  methods: {
    ...mapActions({
      fetchAssets: 'assets/socket_fetchAssetData'
    }),
    ...mapMutations({
      setDrawerItems: 'app/setDrawerItems'
    }),
    initDrawerItems () {
      const items = JSON.parse(JSON.stringify(this.drawerItems)) // Clone object to avoid modifying it directly
      const rootIndex = items.findIndex(r => r.ns === config.ns) // Save index so we can insert the updates on the same place
      const root = items[rootIndex] // Current drawer item
      const rootChildren = root.children.length ? JSON.parse(JSON.stringify(root.children)) : null

      if (!root) return // Root item not found

      if (!Array.isArray(root.children)) root.children = [] // Make sure we always have an Array to work with

      for (const option of this.assetOptions) {
        const toAdd = {
          nav: true, // Is this route shown in the main nav?
          // path: `/${config.ns}/${option}/${a}`,
          path: `/${config.ns}/${option}`,
          icon: '',
          label: option,
          ns: config.ns
        }
        root.children = root.children.concat(toAdd)
      }
      root.children = root.children.sort((a, b) => {
        if (rootChildren) {
          const isInRoot = rootChildren.find(c => c.label === a.label || c.label === b.label)
          if (isInRoot) return 1
        }

        return a.label > b.label ? 1 : -1
      })

      // Replace old item with the updated item
      items.splice(rootIndex, 1, root)

      // Set updated object
      this.setDrawerItems(items)
    }
  },
  watch: {
    matrixPending (isPending) {
      if (!isPending) this.fetchAssets()
    }
  }
}
</script>
